/* Importing Select SCSS file. */

/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';
@import 'ngx-lightbox/lightbox.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

.datatable-row-center :nth-child(2) > .datatable-body-cell-label,
.datatable-row-center :nth-child(3) > .datatable-body-cell-label,
.datatable-row-center :nth-child(5) > .datatable-body-cell-label,
.datatable-row-center :nth-child(6) > .datatable-body-cell-label,
.datatable-row-center :nth-child(7) > .datatable-body-cell-label,
.datatable-row-center :nth-child(8) > .datatable-body-cell-label {
  text-align: center;
}

.datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(2),
.datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(3),
.datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(5),
.datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(6),
.datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(7),
.datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(8) {
  text-align: center;
}

.score-standings .datatable-row-center :nth-child(1) > .datatable-body-cell-label,
.score-standings .datatable-row-center :nth-child(2) > .datatable-body-cell-label,
.score-standings .datatable-row-center :nth-child(3) > .datatable-body-cell-label,
.score-standings .datatable-row-center :nth-child(4) > .datatable-body-cell-label,
.score-standings .datatable-row-center :nth-child(5) > .datatable-body-cell-label,
.score-standings .datatable-row-center :nth-child(6) > .datatable-body-cell-label,
.score-standings .datatable-row-center :nth-child(7) > .datatable-body-cell-label,
.score-standings .datatable-row-center :nth-child(8) > .datatable-body-cell-label,
.score-standings .datatable-row-center :nth-child(9) > .datatable-body-cell-label,
.score-standings .datatable-row-center :nth-child(10) > .datatable-body-cell-label {
  text-align: left !important;
}

.score-standings .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(1),
  .score-standings .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(2),
  .score-standings .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(3),
  .score-standings .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(4),
  .score-standings .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(5),
  .score-standings .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(6),
  .score-standings .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(7),
  .score-standings .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(8),
  .score-standings .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(9),
  .score-standings .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(10) {
  text-align: left !important;
}
.score-standings .ngx-datatable .datatable-body-cell, .score-standings .ngx-datatable .datatable-header-cell
{
  min-width: 70px !important;
}


.datatable-row-group .datatable-body-cell {
  border-top-width: 0px;
  border: none;
}

.actionbtn > div {
  width: auto !important;
}

.step {
  pointer-events: none !important;
}

#otpInput div input {
  width: 44px !important;
  height: 60px !important;
  border-radius: 5px;
  color: #ffffff;
  background: #1a2f48 !important;
  margin: 13px 13px 0 0;
  border: none;
}

#ngOtpInputJoinCommunity div input {
  width: 45px !important;
  height: 66px !important;
  border-radius: 5px;
  color: #000000;
  border: 1px solid #e0e3e8 !important;
  background: transparent;
  margin-right: 10px;
  border: none;
}
[data-mode='dark'] #ngOtpInputJoinCommunity div input {
  background: #1a2f48 !important;
  border: 1px solid #92afd326 !important;
  color: #ffffff !important;
}

#ngOtpInputJoinCommunity div input:last-child {
  margin: 0;
}
#otpInput div input:last-child {
  margin: 0;
}

.swal2-popup.swal2-modal.custom-popup-class {
  width: 22em;
  padding-bottom: 80px;
  background-color: #ffffff;
  display: grid;
}
.swal2-popup.swal2-modal.custom-button-popup-class {
  width: 22em;
  padding-bottom: 40px;
  background-color: #ffffff;
  display: grid;
}
.swal2-popup.swal2-modal.custom-popup-class-conformation {
  width: 22em;
  background-color: #ffffff;
}

.ck-content {
  min-height: 200px !important;
}

#ckeditor-light .ck-content {
  background: transparent !important;
  color: black !important;
}

#ckeditor-light .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: #e2e8f0 !important;
}

#ckeditor-dark .ck-content {
  background: transparent !important;
  color: white !important;
}

#ckeditor-dark .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: #334155 !important;
}

.openCommunity
  .ngx-datatable
  .visible
  .datatable-body
  .datatable-body-cell-label
  .datatable-tree-button {
  display: none !important;
}

.openCommunity .ngx-datatable .visible {
  display: flex;
  flex-direction: column;
  height: 35rem;
}
.ngx-datatable .datatable-footer {
  margin-top: auto !important;
  padding-left: 15px;
  padding-right: 15px;
  overflow: inherit !important;
  // margin-bottom: 6px;
  // min-width: 400px;
}
.ngx-datatable .datatable-footer .datatable-footer-inner {
  // height: auto !important;
  justify-content: center;
  // text-align: center;
  align-items: center;
}
.pagination-controls ul {
  justify-content: center;
  flex-wrap: nowrap;
}

//table empty column message

.empty-row {
  color: rgb(245, 58, 58);
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}
.loading-text {
  position: fixed !important;
  top: 50% !important;
}

.disable-pointer-events {
  pointer-events: none;
}

.eventWarningPopup {
  color: black !important;
}

input[type='file'] {
  height: 48px;
}

input[type='file']::-webkit-file-upload-button {
  /* Webkit browsers */
  visibility: hidden;
}

input[type='file']::before {
  /* Firefox */
  content: ''; /* This is necessary for the icon to show */
  display: inline-block;
  width: 100%;
  height: 100%;
  /* background: url('your-custom-icon.png') no-repeat center; */
  background-size: cover;
  cursor: pointer; /* Set cursor to pointer to indicate clickable area */
}

.file-input-container {
  position: relative;
}

.file-label {
  position: absolute;
  top: 0;
  left: 10px;
  right: 80px; /* Adjust as needed */
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.upload-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.custom-ng-select > div {
  border: none !important;
  background-color: transparent !important;
  // min-width: 310px;
  box-shadow: none !important;
}

[data-mode='dark']
  .flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: #132337 !important;
}

// .flatpickr-calendar.animate.open {
//   z-index: 1 !important;
// }

[data-mode='dark'] .flatpickr-day.today {
  background-color: transparent !important;
  border: none !important;
  // cursor: not-allowed !important;
  // color: #5885bc !important;
}

[data-mode='light'] .flatpickr-day.today {
  background-color: transparent !important;
  border: none !important;
  color: #1e293b !important;
}

[data-mode='light'] .flatpickr-day.selected {
  --tw-border-opacity: 1 !important;
  border-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(239 246 255 / var(--tw-text-opacity)) !important;
}

[data-mode='dark'] .flatpickr-day.selected {
  --tw-border-opacity: 1 !important;
  border-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(239 246 255 / var(--tw-text-opacity)) !important;
}

.forgotSelect .ng-select .ng-select-container {
  background-color: #ffffff0f !important;
  border-color: #284365 !important;
  color: #94a3b8 !important;
  height: 47px;
}

.forgotSelect .ng-select.ng-select-opened > .ng-select-container {
  background-color: #ffffff0f !important;
}

.forgotSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #132337 !important;
  color: #92afd3;
  border-color: #233a57;
}
[data-mode='dark']
  .ng-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  color: #ffffff;
}
.selectDropdown
  :is([data-mode='dark'] .ng-select.ng-select-opened > .ng-select-container) {
  background-color: #1a2f48 !important;
}
.selectDropdown
  .ng-select
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  font-size: 12px;
  top: 8px;
  color: #000000 !important;
  padding-right: 15px;
}
[data-mode='dark']
  .selectDropdown
  .ng-select
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  color: #bcc9dd !important;
}
[data-mode='light'] .slick-next:before {
  color: #000000;
}
[data-mode='light'] .slick-prev:before {
  color: #000000;
}
.slick-next:before {
  font-size: 24px;
}
.slick-prev:before {
  font-size: 24px;
}
.ng-select .ng-arrow-wrapper {
  display: none;
}

// select input

.custom-ng-select .ng-select-container .ng-arrow-wrapper {
  display: none;
}
.custom-ng-select .ng-select-container {
  min-height: 45px;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: 50%;
  transform: translateY(-50%);
}
.custom-ng-select .ng-value-container {
  margin-left: 24px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: inherit;
  padding-left: 15px;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  top: inherit;
}

//
.ng-select.ng-select-single.custom-ng-select .ng-select-container {
  height: 45px;
  position: relative;
}
.ng-select.ng-select-single.custom-ng-select .ng-select-container:after {
  content: '\ea4e';
  font-family: 'remixicon';
  font-size: 1.1rem;
  position: absolute;
  right: 0.6rem;
  color: #bcc9dd;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.ng-select-multiple.ng-select-searchable .ng-select-container:after {
  content: '\ea4e';
  font-family: 'remixicon';
  font-size: 1.1rem;
  position: absolute;
  right: 0.6rem;
  color: #bcc9dd;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.custom-ng-select .ng-value-container {
  margin-left: 0;
}
.ng-select .ng-select-container .ng-value-container {
  padding: 0 1rem;
}
.ng-select.custom-ng-select .ng-value {
  font-size: 12px;
  // width: 100%;
  position: relative;
}

//
.ng-select.ng-select-single.custom-ng-select.select-no-arrow
  .ng-select-container:after {
  display: none;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  padding-bottom: 1px;
}
.ngx-datatable .datatable-footer .page-count {
  flex: none !important;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li:first-child {
  display: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li:last-child {
  display: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager {
  display: flex !important;
  justify-content: end;
  align-items: center;
  column-gap: 10px;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a i::before {
  padding: 0;
}
// .ngx-datatable .datatable-footer .datatable-pager .pager li{
//   margin-right: ;
// }
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  display: flex !important;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  border-radius: 4px;
  font-size: 16px;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a i {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a {
  color: #ffffff;
  background-color: #3b82f6;
  color: #ffffff;
  border-radius: 4px;
}
[data-mode='dark']
  .ngx-datatable
  .datatable-footer
  .datatable-pager
  .pager
  li.disabled
  a {
  cursor: default !important;
  color: #5885bc;
}
[data-mode='dark']
  .ngx-datatable
  .datatable-footer
  .datatable-pager
  .pager
  li
  a {
  background-color: #3b82f61a;
  border: 1px solid #233a57;
  color: #92afd3;
}
[data-mode='dark']
  .ngx-datatable
  .datatable-footer
  .datatable-pager
  .pager
  li
  a:hover {
  color: #ffffff;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  color: #64748b;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
  cursor: default !important;
  color: #94a3b8;
}

/*.manage-event-shedule .datatable-row-center :nth-child(1) {
  text-align: center !important;
}
.manage-event-shedule
  .datatable-row-center
  :nth-child(1)
  > .datatable-body-cell-label {
  text-align: center !important;
}*/
.manage-event-shedule .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(2), 
.manage-event-shedule .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(3), 
.manage-event-shedule .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(5), 
.manage-event-shedule .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(6), 
.manage-event-shedule .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(7), 
.manage-event-shedule .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(8) { text-align: left; }
.slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
}
.map-container {
  border-radius: 6px 6px 0 0;
}
.ngx-datatable,
.datatable-body {
  //overflow: inherit !important;
}
.ng-dropdown-panel .ng-dropdown-header {
  padding: 0 !important;
  border: none;
}
.selectDropdown.howtoVideo .ng-select .ng-clear-wrapper {
  margin-right: 26px;
}
.selectDropdown.orgDatatable .ng-select .ng-clear-wrapper {
  margin-right: 26px;
  margin-top: 5px;
}
.customDropdown
  .ng-select-multiple.ng-select-searchable
  .ng-select-container:after {
  top: 10px !important;
  align-items: start;
}
[data-mode='dark'] .ngx-datatable .datatable-body .datatable-row-wrapper {
  border-bottom: 1px solid #1d334e;
}
[data-mode='light'] .ngx-datatable .datatable-body .datatable-row-wrapper {
  border-bottom: 1px solid #e2e8f0;
}
.selectDropdown.customDropdown
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container {
  padding-right: 30px !important;
}
.checkIcon svg {
  bottom: 0 !important;
}
body.ctmno-scroll {
  overflow: hidden !important;
}

.mobile-frame-box { max-width: 350px; width: 100%; margin: 0 auto; position:relative; z-index: 2; }
.mobile-frame-sub-box { position: absolute; left: 0.8rem; right: 0.8rem; top: 0.8rem; bottom: 0; z-index: -1; background: #fff; border-radius: 2rem 2rem 0 0;  padding: 2.2rem 0 1rem; }
.mobile-img { pointer-events: none; width: 100%; }
.chat-box { margin: 0.9rem 1.9rem; width: 70%; margin-left: auto; }
.chat-text-box { background: #448AF7; position: relative; }
.chat-time { display: block; color: #000; text-align: right; opacity: 0.5; margin-top: 0.5rem; font-size: 0.7rem; }
.arrow-box { width: 21px; height: 20px; background: #448AF7 0% 0% no-repeat padding-box; opacity: 1; display: block; clip-path: polygon(17% 38%, 60% 33%, 61% 58%, 76% 73%, 95% 88%, 53% 85%, 31% 70%); position: absolute; right: -8px; bottom: -5px; }
.chat-box-input { position: sticky; bottom: 0; left: 0; right: 0; background: #d1d3d9; padding: 0 0.2rem; display: flex; z-index: 5; border-radius: 0 0 2.5rem 2.5rem; flex-direction: column; }
.chat-box-input .form-input, .chat-box-input .form-btn { border-radius: 50px; }
.chat-input-box { position: relative; display: flex; align-items: center; padding: 0.7rem 0.8rem; background-color: #fff; }
.chat-box-input .input-attach { position: absolute; left: 1.55rem; top: 1.35rem; z-index: 2; }
.chat-box-input .form-input { padding-left: 2rem; }
.chat-list {
  height: calc(100% - 3.9rem);
  overflow: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}
.chat-list-android { height: calc(100% - 20.9rem); overflow: auto; margin-top: 1rem; }
.keyboard-img { padding: 0 0.3rem; position: relative; overflow: hidden; border-radius: 0 0 2.5rem 2.5rem; }
.msg-requirenment p { background: #000000CC; padding: 0.6rem 0.7rem; border-radius: 5px; margin: 0; width: 100%; font-size: 12px; line-height: 1.25; font-weight: 400; letter-spacing: 0.02rem; }
.msg-requirenment { position: sticky;
  bottom: 0px;
  margin: 1rem;
  margin-top: auto;
  margin-bottom: 0; }
.popup-datable .ngx-datatable .datatable-body .datatable-row-wrapper {
  border: 0;
  padding: 0 0.4rem;
  min-width: 100%;
  width: 100% !important;
}
.popup-datable datatable-selection,
.popup-datable .datatable-scroll,
.popup-datable .datatable-row-wrapper {
  width: 100% !important;
  display: block !important;
  min-width: 100% !important;
}
.popup-datable .datatable-body-row,
.popup-datable .datatable-row-group {
  min-width: 100% !important;
  width: 100% !important;
}
.popup-datable .datatable-row-group.datatable-row-left {
  display: none !important;
}

.insurance-shadow-box {
  box-shadow: 0px 0px 6px #00000029;
}
.insurenceData-mobile {
  display: none;
  box-shadow: 0px 0px 6px #00000029;
}
.title-btn { white-space: nowrap; }
.ngx-datatable .datatable-body .datatable-body-row>div.datatable-row-right { display:none; }


/* Responsive css
==============================================*/
@media only screen and (max-width: 767px) {
  .insurance-shadow-box.shadow-md-only {
    box-shadow: none;
  }
  .insurenceData-mobile {
    display: block;
  }
  .insurenceData-desktop {
    display: none;
  }
}

@media only screen and (max-width: 639px) {
  #otpInput div input {
    width: 34px !important;
    height: 50px !important;
    margin: 7px 7px 0 0;
  }
}
@media only screen and (max-width: 575px) {
  .registerButton{
    margin-bottom: 70px !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.selectDropdown.new-select .ng-select-container {
  height: 30px !important;
  min-height: 30px !important;
  position: relative !important;
}
.ngx-datatable .datatable-body { min-width:100%; }
/*.popup-datable { max-height:80vh; overflow:auto; }*/
.table-loader { position: absolute; z-index: 1; left: 0; top: 0; right: 0; bottom: 0; margin: auto; height: 32px; width: 32px; }
.ng-select.ng-select-multiple .ng-select-container .ng-value-container { width:100%; }
#navbar-nav li img { width: 22px; height: 22px; object-fit:cover; padding:1px; border-radius:2px; border-width: 0.5px; border-style:solid; }

#navbar-nav .dropdown-button::before { transform:rotate(0deg); transition:0.25s all; }
#navbar-nav .sub-menu-active .dropdown-button::before  { transform:rotate(90deg); }
.sub-menu-active div > .hover\:bg-vertical-menu-item-bg-hover:hover { background:transparent; color:#fff; }
.sub-menu-active .dropdown-content { padding-top: 0.01rem; }


.team-info-tabs { /*max-width:1160px;*/ width:100%; letter-spacing: 0.03rem; }
.team-info-tabs >div { width:20%; position: relative; z-index: 1; }
.team-info-tabs .border-dashed-custom { position: absolute; right: 0; width: 100%; left: 50%; z-index: -1; }
.team-step-form { /*max-width:1160px;*/ width:100%; letter-spacing: 0.03rem; }
.team-step-form-data { /*max-width:825px;*/ width:100%; }
/*.nowrap-table [role="table"] { position:relative; width:100%; overflow:auto; }
.nowrap-table datatable-body-cell, .nowrap-table datatable-header-cell { min-width:100px; white-space: nowrap; }*/
/*.noScrollbarTable .popup-datable { max-height: inherit; overflow: inherit; }*/

/*.nowrap-table .roster-list-table { position: relative; max-height: 60vh; overflow-y: auto; overflow-x:hidden; }*/
.roster-list-table .ngx-datatable > div[role="table"] { position: relative; max-height: 80vh; overflow-y: auto; overflow-x:auto; padding-bottom: 1rem; -webkit-overflow-scrolling: touch; }
.roster-list-table .datatable-header, .roster-list-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner { position: sticky; top: 0; z-index: 25; }
.roster-list-table .datatable-body-cell-label span.break-words { word-break: break-all; }
.roster-list-table .ngx-datatable .datatable-body { display:inline !important; }
.roster-list-table .ngx-datatable > div[role="table"]::-webkit-scrollbar { width: 0px; background: transparent; /* make scrollbar transparent */ -webkit-overflow-scrolling: touch; }
.roster-list-table .ngx-datatable > div[role="table"] { scrollbar-width:none; scrollbar-color: transparent transparent; -webkit-overflow-scrolling: touch; }
.roster-list-table .ngx-datatable .datatable-footer { background:#fff; bottom: 0; z-index: 11; border-top: 1px solid #e2e8f0; }
:is([data-mode=dark]) .roster-list-table .ngx-datatable .datatable-footer { background:#132337; border-color: #1d334e; }
.roster-list-table .ngx-datatable .datatable-row-wrapper:last-child { border-bottom: 0; }
.ngx-datatable .datatable-body-cell, .ngx-datatable .datatable-header-cell { min-width: 150px; }
.roster-list-table .ngx-datatable .datatable-header { overflow:inherit; min-width: 100%; display: contents; }
.post-user-name { font-weight:500; }



@media only screen and (max-width: 991px) {
  .team-info-tabs { font-size: 11px; }
}
@media only screen and (max-width: 767px) {
  .team-info-tabs { font-size: 11px; }
}
@media only screen and (max-width: 650px) {
  .team-info-tabs .border-dashed-custom { display:none; }
  .team-info-tabs { flex-wrap:wrap; }
  .team-info-tabs > div { width: 33.333333%; margin-bottom:1.3rem; }
  .ngx-datatable .datatable-body-cell, .ngx-datatable .datatable-header-cell { min-width: 150px; }
}

.tooltip { position: relative; display: inline-block; }
.tooltip-text { visibility: hidden; font-size: 13px; font-weight: 500; font-family: "Poppins", sans-serif; background-color: #e2e8f0; color: #64748b; text-align: center; border-radius: 6px; padding: 5px 12px; position: absolute; z-index: 1; bottom: 100%; left: 50%; transform: translateX(-50%); margin-bottom: 0; opacity: 0; visibility: hidden; transition: 0.35s all; }
.copy { @extend .tooltip-text; }
.copied { @extend .tooltip-text; background-color: #249782; color: #fff; }
.tooltip-text:after, .copied:after, .copy:after { content: ""; position: absolute; left: 0; right: 0; top: 100%; height: 0; width: 0; border-top: 5px solid #e2e8f0; border-left: 5px solid transparent; border-right: 5px solid transparent; margin: auto; transition: 0.35s all; }
.tooltiptext.copied:after { border-top-color: #249782; }
.tooltip:hover .tooltiptext { visibility: visible; opacity: 1; margin-bottom: 10px; }
.datatable-body-cell-label .tooltip-text {  right: 100%; left: auto; bottom: auto; top: 50%; transform: translateY(-50%); margin: 0; }
.datatable-body-cell-label .tooltip-text::after { left: 100%; right: auto; top: 0; bottom: 0; border-left: 5px solid #e2e8f0; border-top: 5px solid transparent; border-bottom: 5px solid transparent; }
.datatable-body-cell-label .tooltip:hover .tooltip-text { opacity: 1; visibility: visible; margin-right: 10px; }

.ctmPostLink{
  color: cornflowerblue  !important;
  text-decoration: underline  !important;
}

// .select-ellipsis .ng-select .ng-select-container .ng-value-container .ng-placeholder { padding-right: 15px; display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.circleName + span { text-align: left; white-space: nowrap; }

  ::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="transparent" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  }
@media (max-width: 639px) {
  ::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  }
  input[type="time"]::-webkit-calendar-picker-indicator{
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" fill="%23bbbbbb"><g><polygon class="cls-1" points="13.18 6.07 10.85 6.07 10.85 14.61 11.16 14.61 13.18 14.61 17.87 14.61 17.87 12.28 13.18 12.28 13.18 6.07"/><path class="cls-1" d="M12,0C5.37,0,0,5.37,0,12s5.37,12,12,12,12-5.37,12-12S18.63,0,12,0ZM12,21.51c-5.24,0-9.51-4.26-9.51-9.51S6.76,2.49,12,2.49s9.51,4.26,9.51,9.51-4.26,9.51-9.51,9.51Z"/></g></svg>');  
  }
}

.push-notification-table .ngx-datatable .datatable-body-cell, .push-notification-table .ngx-datatable .datatable-header-cell {  min-width: 130px !important; white-space: nowrap; }
/*.push-notification-table .ngx-datatable .datatable-body { overflow: auto !important; }*/
.push-notification-table .popup-datable .ngx-datatable .datatable-body .datatable-row-wrapper { padding: 0; }
.push-notification-table .ngx-datatable .datatable-footer .datatable-footer-inner { height: auto !important; }
.push-notification-table .datatable-row-center:nth-child(1) { display: block; }
.push-notification-table .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap, .push-notification-table .ngx-datatable .datatable-body-cell .datatable-body-cell-label { text-align: left; }
.push-notification-table .datatable-header .datatable-header-cell:nth-child(1) .datatable-header-cell-template-wrap, .push-notification-table .ngx-datatable .datatable-body-cell:nth-child(1) .datatable-body-cell-label { text-align: left; }
.manage-users .ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap { text-align: center; }

@media (max-width: 767px) {
  #page-topbar .h-header { height: 3.375rem; }
}

// .register-league-table .ngx-datatable .datatable-body-cell, .register-league-table .ngx-datatable .datatable-header-cell { min-width: inherit; }
// .register-league-table .popup-datable .datatable-body-row, .register-league-table .popup-datable .datatable-row-group { min-width: inherit !important; width: inherit; }
.register-league-table .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(2), .register-league-table .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(3), .register-league-table .datatable-row-center :nth-child(2) > .datatable-body-cell-label, .register-league-table .datatable-row-center :nth-child(3) > .datatable-body-cell-label { text-align: left; }
.register-league-table .datatable-row-center :nth-child(2) > .datatable-body-cell-label div, .register-league-table .datatable-row-center :nth-child(3) > .datatable-body-cell-label div { justify-content: start; }
.product-pack-table .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(1), .product-pack-table .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(2), .product-pack-table .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(3) { text-align: left;  }
.product-pack-table .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(4) { text-align: center; }
// .product-pack-table .datatable-row-center :nth-child(4) > .datatable-body-cell-label .actionbtn { margin-left: 20px; }
.product-pack-table .datatable-row-center :nth-child(1) > .datatable-body-cell-label, .product-pack-table .datatable-row-center :nth-child(2) > .datatable-body-cell-label, .product-pack-table .datatable-row-center :nth-child(3) > .datatable-body-cell-label { text-align: left; display: inline-block; }
.category-list-table .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(2) > .datatable-header-cell-template-wrap { text-align: right; position: relative; right: 8px; }
.category-list-table .datatable-row-center :nth-child(2) > .datatable-body-cell-label .actionbtn { display: flex; justify-content: end; }
.manage-users .popup-datable .datatable-row-center .datatable-body-cell-label { 
  text-align: left; 
  // white-space: nowrap; 
  // overflow: hidden; 
  text-overflow: ellipsis; 
}

.manage-users .ngx-datatable .datatable-header .datatable-header-cell:last-child .datatable-header-cell-template-wrap { text-align: center; }
.manage-users div:empty { display: none; }
.manage-users .ngx-datatable.fixed-header .datatable-header .datatable-header-inner { min-width: 100%; }
.manage-users .ngx-datatable .datatable-header { min-width: inherit; overflow: inherit; }
.manage-users .popup-datable .ngx-datatable .datatable-body .datatable-row-wrapper { padding-left: 0; padding-right: 0; }

.manage-event-shedule .popup-datable .datatable-row-center .datatable-body-cell-label { text-align: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.manage-event-shedule .ngx-datatable .datatable-header .datatable-header-cell:last-child .datatable-header-cell-template-wrap { text-align: center; }
.manage-event-shedule div:empty { display: none; }
.manage-event-shedule .ngx-datatable.fixed-header .datatable-header .datatable-header-inner { min-width: 100%; }
.manage-event-shedule .ngx-datatable .datatable-header { min-width: inherit; overflow: inherit; }
.manage-event-shedule .popup-datable .datatable-row-center .datatable-body-cell-label .text-center span { text-align: left; width: 100%; }
.manage-event-shedule .popup-datable .ngx-datatable .datatable-body .datatable-row-wrapper { padding-left: 0; padding-right: 0; }

/* make a scroll bar on table */
// .popup-datable .ngx-datatable > div[role="table"] { position: relative; max-height: 80vh; overflow-y: auto; overflow-x:auto; padding-bottom: 0; -webkit-overflow-scrolling: touch; }
// .popup-datable .datatable-header, .popup-datable .ngx-datatable.fixed-header .datatable-header .datatable-header-inner { position: sticky; top: 0; z-index: 25; }
// .popup-datable .datatable-body-cell-label span.break-words { word-break: break-all; }
// .popup-datable .ngx-datatable .datatable-body { display:inline !important; }
// .popup-datable .ngx-datatable > div[role="table"]::-webkit-scrollbar { width: 0px; background: transparent; -webkit-overflow-scrolling: touch; }
// .popup-datable .ngx-datatable > div[role="table"] { scrollbar-width:none; -webkit-scrollbar-width:none; scrollbar-color: transparent transparent; -webkit-scrollbar-color: transparent transparent; -webkit-overflow-scrolling: touch; }
// .popup-datable .ngx-datatable .datatable-footer { background:#fff; bottom: 0; z-index: 11; border-top: 1px solid #e2e8f0; position: sticky; bottom: 0; z-index: 25; margin: 0; padding-bottom: 1rem; }
// :is([data-mode=dark]) .popup-datable .ngx-datatable .datatable-footer { background:#132337; border-color: #1d334e; }
// .popup-datable .ngx-datatable .datatable-row-wrapper:last-child { border-bottom: 0; }
// .popup-datable .ngx-datatable .datatable-header { overflow:inherit; min-width: 100%; display: contents; }
// .popup-datable .dropdown.actionbtn .dropdown-menu { transform: none !important; z-index: 999; }
// .popup-datable .ngx-datatable { position: static; }

.package-products-box { position: relative; z-index: auto; }
.package-products-box:hover { z-index: 3; }

.planPaymentDates .ng-select.ng-select-single.custom-ng-select .ng-select-container:after { content: '\eb28'; font-family: 'remixicon'; font-size: 1.1rem; position: absolute; right: 0.6rem; color: #bcc9dd; top: 0; bottom: 0; margin: auto; display: flex; align-items: center; justify-content: center; z-index: 9; }
.form-input.flatpickr-input { /*padding-right: 2.3rem;*/ padding-right: 2.8rem; text-overflow: ellipsis; }

// commenting because it breaking the scroll in the website 
// body.no-scroll {
//   overflow: hidden;
// }
div:focus { z-index: 99; }
input {
  white-space: nowrap;      /* Prevents text from wrapping to a new line */
  overflow: hidden;         /* Hides overflowed text */
  text-overflow: ellipsis;  /* Adds ellipsis (...) for overflowed text */
  width: 100%;              /* Adjust width as needed */
}

.sub-menu-close::before {
  transform: rotate(0deg) !important;
}

#insuranceDocumentUrl {
  appearance: none;
  content: '';
  visibility: hidden;
}

#waiversCheckbox {
  -webkit-appearance: checkbox;
  appearance: checkbox;
  width: auto;
  height: auto;
}

.discount-code-table .ngx-datatable .datatable-body-cell:nth-child(4), .discount-code-table .ngx-datatable .datatable-header-cell:nth-child(4), .payment-plans-table .ngx-datatable .datatable-body-cell:nth-child(4), .payment-plans-table .ngx-datatable .datatable-header-cell:nth-child(4) { min-width: 230px; }
// .discount-code-table .ngx-datatable, .discount-code-table .datatable-body { overflow: hidden !important; }
.popup-datable { position: relative; overflow: auto; }
/*.popup-datable::-webkit-scrollbar { width: thin; background: transparent; -webkit-overflow-scrolling: touch; }
.popup-datable { scrollbar-width:thin; scrollbar-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1); -webkit-overflow-scrolling: touch; }*/
.file-input-container .form-file { opacity: 0; }

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner { min-width: 100%; }
.delete-event-popup:first-child {
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.nowrap { white-space: nowrap; }
.lacrroseNumIcon i {
  font-size: 30px;
  width: 30px;
  height: 30px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #249782;
  border-radius: 100%;
}

@media (min-width: 992px) {
  .manage-table-btn .table-top-btn { flex-wrap: wrap; min-width: 410px; padding-left: 10px; }
  .manage-table-btn .table-top-btn .btn { margin-top: 5px; margin-bottom: 5px; }
}
@media (min-width: 1400px) {
  .manage-table-btn .table-top-btn { min-width: 614px; }
}

[data-sidebar-size="sm"] .menu-bottom { display: none; }

.ng-select.ng-select-multiple .ng-select-container .ng-value-container { max-height: 56px; overflow: auto; scrollbar-width:thin; scrollbar-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1); -webkit-overflow-scrolling: touch; }
.ng-select.ng-select-multiple .ng-select-container .ng-value-container::-webkit-scrollbar { width: thin; background: transparent; -webkit-overflow-scrolling: touch; }
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label { white-space: normal; }
.ng-select.ng-select-multiple .ng-clear-wrapper { display: flex; align-items: center; justify-content: center; margin-left: 4px; top: -3px; }
.ng-dropdown-panel.ng-select-multiple .scroll-host { scrollbar-width:thin; scrollbar-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1); -webkit-overflow-scrolling: touch; }
.ng-dropdown-panel.ng-select-multiple .scroll-host::-webkit-scrollbar { width: thin; background: transparent; -webkit-overflow-scrolling: touch; }
// .product-plan-table.product-pack-table .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(4) { text-align: left; padding-left: 40px; }
.product-plan-table .popup-datable .datatable-row-center .datatable-body-cell-label {
  text-overflow: initial;
}
.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:last-child .datatable-header-cell-template-wrap {
  text-align: left;
}
// .package-history-table, .product-plan-table, .single-event-table .ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
//   text-align: center;
// }
// .package-history-table, .product-plan-table, .single-event-table .popup-datable .datatable-row-center .datatable-body-cell-label {
//   text-align: center;
// }
.payment-plans-table .ngx-datatable .datatable-header .datatable-header-cell:last-child .datatable-header-cell-template-wrap {
  text-align: center !important;
}
.payment-plans-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(3) .datatable-header-cell-template-wrap {
  text-align: left;
}
.view-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2) .datatable-header-cell-template-wrap{
  text-align: left !important;
}
.view-package-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2) .datatable-header-cell-template-wrap{
  text-align: center !important;
  width: 100%;
}
.view-package-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2) .datatable-header-cell-template-wrap{
  text-align: center !important;
}
.payment-plan-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(6) .datatable-header-cell-template-wrap {
  text-align: center;
}

.packages-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2) .datatable-header-cell-template-wrap{
  text-align: center !important;
}

.ri-add-circle-line{
 margin-left: 35px !important;
}

// package-history-table UI Changes
.package-history-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2) .datatable-header-cell-template-wrap{
  text-align: left !important;
}
.package-history-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(3) .datatable-header-cell-template-wrap{
  text-align: left !important;
}
.package-history-table .ngx-datatable .datatable-body .datatable-body-cell:nth-child(3) .datatable-body-cell-label div{
  text-align: left !important;
}
.package-history-table .ngx-datatable {
  .datatable-header .datatable-header-cell:nth-child(4),
  .datatable-header .datatable-header-cell:nth-child(5),
  .datatable-header .datatable-header-cell:nth-child(6),
  .datatable-header .datatable-header-cell:nth-child(7),
  .datatable-header .datatable-header-cell:nth-child(9),
  .datatable-header .datatable-header-cell:nth-child(10),
  .datatable-header .datatable-header-cell:nth-child(11){
    // text-align: center;
    justify-content: center;
  }
  .datatable-body .datatable-body-cell:nth-child(4) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(5) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(6) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(7) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(9) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(10) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(11) .datatable-body-cell-label {
    text-align: center !important;
  }
}

.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:last-child .datatable-header-cell-template-wrap {
  text-align: center;
}


// payment-plan-table UI Changes
.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(6),
.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(7),
.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(12){
  justify-content: center;
}
.product-plan-table .ngx-datatable .datatable-body .datatable-body-cell:nth-child(5) .datatable-body-cell-label,
.product-plan-table .ngx-datatable .datatable-body .datatable-body-cell:nth-child(6) .datatable-body-cell-label {
  text-align: center;
}
.manage-users.product-plan-table .ngx-datatable .datatable-body .datatable-body-cell:nth-child(4) .datatable-body-cell-label {
  text-align: left !important;
}
.product-plan-table .popup-datable .datatable-row-center .datatable-body-cell:nth-child(9) .datatable-body-cell-label {
  text-align: center !important;
}


// .ngx-datatable .datatable-body .datatable-body-cell .datatable-body-cell-label span{
//   font-weight: 400;
// }

.payment-plans-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2) .datatable-header-cell-template-wrap{
  text-align: left;
}
.payment-plans-table .ngx-datatable .datatable-body .datatable-body-cell:nth-child(2) .datatable-body-cell-label{
  text-align: left;
}
.view-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(3) .datatable-header-cell-template-wrap{
  text-align: left;
}
.view-plan-table .ngx-datatable .datatable-body .datatable-body-cell:nth-child(3) .datatable-body-cell-label{
  text-align: left;
}

.packages-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4),
.packages-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(5),
.packages-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(6),
.packages-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(8) {
  justify-content: center;
}


.packages-detail .ngx-datatable .datatable-body .datatable-body-cell:nth-child(3) .datatable-body-cell-label div{
  justify-content: start;
}
.packages-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2) .datatable-header-cell-template-wrap{
  text-align: left !important;
}
.packages-detail .ngx-datatable .datatable-body .datatable-body-cell:nth-child(2) .datatable-body-cell-label div{
  justify-content: start;
}

.manage-users.payment-plan-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2),
.manage-users.payment-plan-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(3),
.manage-users.payment-plan-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(8),
.manage-users.payment-plan-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(9) {
  justify-content: center;
}

.manage-users.payment-plan-detail .datatable-body .datatable-body-cell:nth-child(7) .datatable-body-cell-label{
  text-align: center;
}
.manage-users.payment-plan-detail .datatable-body .datatable-body-cell:nth-child(3) .datatable-body-cell-label{
  text-align: center;
}
.manage-users.payment-plan-detail .datatable-body .datatable-body-cell:nth-child(6) .datatable-body-cell-label{
  text-align: center;
}
.manage-users.payment-plan-detail.monthlyDetails .datatable-body .datatable-body-cell:nth-child(4) .datatable-body-cell-label{
  text-align: center !important;
}

.manage-users.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(5) .datatable-header-cell-template-wrap {
 min-width: 100%;
}
.manage-users.product-plan-table .datatable-body .datatable-body-cell:nth-child(5) .datatable-body-cell-label{
  text-align: center;
 }
 .manage-users.product-plan-table .datatable-body .datatable-body-cell:nth-child(7) .datatable-body-cell-label{
  text-align: center;
 }
.manage-users.product-plan-table .datatable-body .datatable-body-cell:nth-child(7) .datatable-body-cell-label span { margin: 0 !important; }
 .manage-users.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(8) .datatable-header-cell-template-wrap{
  text-align: center;
 }
 .manage-users.product-plan-table .datatable-body .datatable-body-cell:nth-child(8) .datatable-body-cell-label{
   text-align: center;
  }
.manage-users.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(10) .datatable-header-cell-template-wrap{
  text-align: left;
}
.manage-users.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(9) .datatable-header-cell-template-wrap {
  min-width: 100%;
}
.manage-users.product-plan-table .datatable-body .datatable-body-cell:nth-child(10) .datatable-body-cell-label{
  text-align: left;
}
// .manage-users.send-email-table .datatable-body .datatable-body-cell:nth-child(1) .datatable-body-cell-label{
//   text-align: center !important;
// }
.manage-users.send-email-table .datatable-body .datatable-body-cell:nth-child(2) .datatable-body-cell-label{
   text-align: center !important;
}

// .manage-users.send-email-table .datatable-body .datatable-body-cell:nth-child(3) .datatable-body-cell-label{
//   text-align: center !important;
// }

.manage-users .popup-datable .datatable-row-center .datatable-body-cell:nth-child(4) .datatable-body-cell-label{
  text-align: center !important;
}

.view-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4) .datatable-header-cell-template-wrap{
  text-align: center;
  margin-right: 10px;
  width: 100%;
}
.view-plan-table .datatable-body .datatable-body-cell:nth-child(4) .datatable-body-cell-label{
  text-align: center;
}
.view-package-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(3) .datatable-header-cell-template-wrap{
  text-align: center;
  margin-right: 10px;
  width: 100%;
}
.view-package-plan-table .datatable-body .datatable-body-cell:nth-child(3) .datatable-body-cell-label{
  text-align: center;
}
// .view-plan-table .datatable-body .datatable-body-cell { width: auto !important; }
.view-plan-table .popup-datable .ngx-datatable .datatable-body .datatable-row-wrapper { padding: 0; }
.view-plan-table .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap span { white-space: nowrap; }

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell{
  white-space: break-spaces !important;
  display: flex;
  align-items: center;
}
.manage-users .ngx-datatable .datatable-header{
  height: auto !important;
}

.manage.users.packages-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4) .datatable-header-cell-template-wrap{
  text-align: center;
 }
 .manage.users.packages-detail .datatable-body .datatable-body-cell:nth-child(4) .datatable-body-cell-label{
  text-align: center;
 }
 .manage.users.packages-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(7) .datatable-header-cell-template-wrap{
  text-align: left;
 }
 .manage.users.packages-detail .datatable-body .datatable-body-cell:nth-child(7) .datatable-body-cell-label{
  text-align: left;
 }
 .manage-users.payment-plan-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2) .datatable-header-cell-template-wrap{
  text-align: center;
 }
 .manage-users.payment-plan-detail .ngx-datatable .datatable-header .datatable-header-cell:nth-child(8) .datatable-header-cell-template-wrap{
  text-align: center;
 }
 .manage-users.payment-plan-detail .datatable-body .datatable-body-cell:nth-child(2) .datatable-body-cell-label{
  text-align: center;
 }
 .packages-detail .ngx-datatable .datatable-header{
  height: auto !important;
  overflow: unset;
}


 // single-event-table UI Changes
// .single-event-table .ngx-datatable .datatable-body .datatable-body-cell:nth-child(3) .datatable-body-cell-label div{
//   text-align: left !important;
// }
.single-event-table .ngx-datatable {
  /* Center align specified header cells */
  .datatable-header .datatable-header-cell:nth-child(1) .datatable-header-cell-template-wrap,
  .datatable-header .datatable-header-cell:nth-child(2) .datatable-header-cell-template-wrap,
  .datatable-header .datatable-header-cell:nth-child(3) .datatable-header-cell-template-wrap,
  .datatable-header .datatable-header-cell:nth-child(8) .datatable-header-cell-template-wrap {
    text-align: left !important;
  }
  .datatable-header .datatable-header-cell:nth-child(4),
  .datatable-header .datatable-header-cell:nth-child(5),
  .datatable-header .datatable-header-cell:nth-child(6),
  .datatable-header .datatable-header-cell:nth-child(7),
  .datatable-header .datatable-header-cell:nth-child(8),
  .datatable-header .datatable-header-cell:nth-child(9) {
    justify-content: center;
  }
  /* Center align specified body cells */
  .datatable-body .datatable-body-cell:nth-child(4) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(5) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(6) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(7) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(8) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(9) .datatable-body-cell-label {
    text-align: center !important;
  }
}

.subscription-history-table .ngx-datatable {
  /* Center align specified header cells */
  .datatable-header .datatable-header-cell:nth-child(2) .datatable-header-cell-template-wrap,
  .datatable-header .datatable-header-cell:nth-child(3) .datatable-header-cell-template-wrap,
  .datatable-header .datatable-header-cell:nth-child(8) .datatable-header-cell-template-wrap {
    text-align: left !important;
  }
  .datatable-header .datatable-header-cell:nth-child(1),
  .datatable-header .datatable-header-cell:nth-child(4),
  .datatable-header .datatable-header-cell:nth-child(5),
  .datatable-header .datatable-header-cell:nth-child(6),
  .datatable-header .datatable-header-cell:nth-child(7),
  .datatable-header .datatable-header-cell:nth-child(8),
  .datatable-header .datatable-header-cell:nth-child(9) {
    justify-content: center;
  }
  /* Center align specified body cells */
  .datatable-body .datatable-body-cell:nth-child(1) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(5) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(5) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(6) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(7) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(8) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(9) .datatable-body-cell-label {
    text-align: center !important;
  }
}


.manage-users.email-manage-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2) .datatable-header-cell-template-wrap{
  text-align: left;
}
.manage-users.email-manage-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(3) .datatable-header-cell-template-wrap{
  text-align: left;
}

.ngx-datatable .datatable-body .datatable-body-cell .datatable-body-cell-label span{
  font-size: 12px !important;
}

 
// row's border realted style

.custom-table .datatable-row-wrapper {
  border-bottom: 2px solid #1D334E !important;
}
[data-mode=light] .custom-table .datatable-row-wrapper{
  border-bottom: 2px solid #ecf1f7 !important;
}

.custom-table .datatable-row-wrapper:last-child {
  border-bottom: none !important; /* Optional: Remove border from the last row */
}

.sk-bucks-admin-list-table {
  .datatable-body .datatable-body-cell:nth-child(1) .datatable-body-cell-label {
    text-align: left !important;
  }
  .datatable-body .datatable-body-cell:nth-child(2) .datatable-body-cell-label {
    text-align: left !important;
  }
  .datatable-body .datatable-body-cell:nth-child(3) .datatable-body-cell-label {
    text-align: center !important;
  }
  .datatable-header .datatable-header-cell:nth-child(1) .datatable-header-cell-template-wrap {
    text-align: left !important;
  }
  .datatable-header .datatable-header-cell:nth-child(2) .datatable-header-cell-template-wrap {
    text-align: left !important;
  }
  .datatable-header .datatable-header-cell:nth-child(3) .datatable-header-cell-template-wrap {
    text-align: center !important;
  }
  .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(3),
  .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(4) {
    display: flex !important;
    justify-content: center !important;
  }
}


// .sk-bucks-admin-list-table .datatable-body .datatable-body-cell:nth-child(4) .datatable-body-cell-label {
//   text-align: center !important;
//   padding-left: 2rem;
// }

// .sk-bucks-admin-list-table .ngx-datatable .datatable-body .datatable-body-row>div {
//   align-items: center;
// }

.popup-datable datatable-selection, .popup-datable .datatable-scroll, .popup-datable .datatable-row-wrapper {
  display: inline-table !important;
}

.payment-plans-table,
.manage-table-btn,
.product-pack-table,
.manage-event-shedule,
.discount-code-table {
  .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:last-child {
    justify-content: center !important;
  }
}

.manage-table-btn .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:first-child {
  justify-content: center;
}
.manage-table-btn .popup-datable .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:first-child {
  justify-content: start !important;
  padding-left: 50px;
}
.manage-table-btn .popup-datable .datatable-body .datatable-body-row .datatable-body-cell:nth-child(1) {
  display: flex;
  justify-content: start !important;
  padding-left: 50px;
}


.user-sk-bucks-history .datatable-header .datatable-header-cell:nth-child(4),
.user-sk-bucks-history .datatable-header .datatable-header-cell:nth-child(5) {
  justify-content: center !important;
}

.user-sk-bucks-history .datatable-body .datatable-body-cell:nth-child(4) .datatable-body-cell-label,
.user-sk-bucks-history .datatable-body .datatable-body-cell:nth-child(5) .datatable-body-cell-label {
  display: flex !important;
  justify-content: center !important;
}


.manage-users.email-manage-table.usercount .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(3){
  justify-content: center;
}
.manage-users.email-manage-table.usercount .ngx-datatable .datatable-body-cell:nth-child(3) .datatable-body-cell-label{
  text-align: center !important;
}
.manage-users.email-manage-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(1){
  padding-left: 50px !important;
}
.manage-users.email-manage-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(4){
  justify-content: center;
}
.manage-users.email-manage-table .ngx-datatable .datatable-body-cell:nth-child(1){
  display: flex;
  align-items: center;
  padding-left: 50px !important;
}

[data-mode='light'] .popup-datable .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell{
  background-color: #e2eaf3 !important;
}
.popup-datable .visible{
  width: fit-content;
}

.manage-users.send-email-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(1){
  padding-left: 50px !important;
}
.manage-users.send-email-table .ngx-datatable .datatable-body-cell:nth-child(1){
  padding-left: 50px !important;
  display: flex;
}
.manage-users.send-email-table .ngx-datatable .datatable-body-cell:nth-child(2){
  display: flex;
  justify-content: center;
}
.manage-users.send-email-table .ngx-datatable .datatable-body-cell:nth-child(3){
  display: flex;
  justify-content: center;
}
.manage-users.send-email-table .ngx-datatable .datatable-body-cell:nth-child(4){
  display: flex;
  justify-content: center;
}
// .manage-users.send-email-table .datatable-body .datatable-body-cell:nth-child(2) .datatable-body-cell-label{
//   text-align: left !important
// }
.manage-users.send-email-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2), .manage-users.send-email-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(3){
  justify-content: center !important;
}
.manage-users.send-email-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4), .manage-users.custom-user-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4) {
  justify-content: center !important;
}
.manage-users.send-email-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(5){
  justify-content: center !important;
}
.recent-sends-table.manage-users.send-email-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4), .manage-users.custom-user-table.recent-sends-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4) {
  justify-content: center !important;
}
.reason_html span {
  font-weight: bold !important;
}
.manage-users .ngx-datatable.fixed-header .datatable-header .datatable-header-inner { padding: 0; }
.manage-users .popup-datable [role="table"] { max-width: 100%; min-width: 100%; }
.manage-users .ngx-datatable.fixed-header .datatable-header .datatable-header-inner { max-width: 100%; min-width: 100%; width: 100%; }

.roster-list-table .datatable-row-center.datatable-row-group .datatable-body-cell-label { text-align: left; }
.roster-list-table .datatable-row-center .datatable-header-cell:last-child { text-align: center; }
.roster-list-table .datatable-row-center .datatable-header-cell:last-child .datatable-header-cell-template-wrap { width: 100%; }

.manage-users.manage-table-btn .popup-datable .datatable-row-center .datatable-body-cell:nth-child(4) .datatable-body-cell-label{
  text-align: left !important;
}
.manage-event-shedule.noScrollbarTable .popup-datable .datatable-row-center .datatable-body-cell:nth-child(7) .datatable-body-cell-label{
  text-align: center;
}
.popup-datable.teamApproval .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(6){
  justify-content: center !important;
}
.popup-datable.teamApproval .datatable-row-center .datatable-body-cell:nth-child(6) .datatable-body-cell-label{
  text-align: center;
}
.manage-event-shedule.childCommunities .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(3){
  justify-content: center !important;
}
.manage-event-shedule.childCommunities .popup-datable .datatable-row-center .datatable-body-cell:nth-child(3) .datatable-body-cell-label{
  text-align: center;
}
.viewAthletes .ngx-datatable .datatable-body .datatable-scroll .datatable-row-wrapper .datatable-body-row .datatable-body-cell:nth-child(2) .datatable-body-cell-label{
  text-align: left !important;
}
.viewAthletes .ngx-datatable .datatable-body .datatable-scroll .datatable-row-wrapper .datatable-body-row .datatable-body-cell:nth-child(3) .datatable-body-cell-label{
  text-align: left !important;
}
.viewAthletes .ngx-datatable .datatable-body .datatable-scroll .datatable-row-wrapper .datatable-body-row .datatable-body-cell:nth-child(4) .datatable-body-cell-label div{
  justify-content: center;
}
.viewAthletes .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(4){
    justify-content: center;
}
[data-mode='light'] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{
  color: #000000 !important;
  background-color: #e2eaf3 !important;
}
// .manage-event-shedule.trainingSchedule .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(6){
//   justify-content: center;
// }
.manage-event-shedule.noScrollbarTable .popup-datable.teamApproval .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(7){
  justify-content: center !important;
}
.manage-event-shedule.noScrollbarTable .popup-datable.teamApproval .datatable-row-center .datatable-body-cell:nth-child(7) .datatable-body-cell-label{
  text-align: center;
}
.manage-event-shedule.noScrollbarTable .popup-datable.teamApproval .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(6){
  justify-content: start !important;
}
.manage-event-shedule.noScrollbarTable .popup-datable.teamApproval .datatable-row-center .datatable-body-cell:nth-child(6) .datatable-body-cell-label{
  text-align: left;
}

.manage-users.custom-list .popup-datable.teamApproval .ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row .datatable-body-cell:nth-child(1){
  display: flex;
  justify-content: start !important;
}

.manage-users.custom-user-table .popup-datable.teamApproval .ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row .datatable-body-cell:nth-child(1){
  display: flex;
  justify-content: start !important;
}

.push-notification-table .popup-datable.teamApproval .ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row .datatable-body-cell:nth-child(1){
  display: flex;
  justify-content: start !important;
}

.popup-datable .ngx-datatable .datatable-body .datatable-row-wrapper { padding: 0; }
.view-coach-table .datatable-body-row .datatable-row-center .datatable-body-cell-label { text-align: left; }

.roster-list-table-team-register .ngx-datatable {
  /* Center align specified header cells */
  .datatable-header .datatable-header-cell:nth-child(2),
  .datatable-header .datatable-header-cell:nth-child(7) {
    justify-content: center !important;
  }
  /* Center align specified body cells */
  .datatable-body .datatable-body-cell:nth-child(2) .datatable-body-cell-label,
  .datatable-body .datatable-body-cell:nth-child(7) .datatable-body-cell-label {
    text-align: center !important;
  }
}

// commenting because it breaking the scroll in the website 
// body.no-scroll { height: 100%; touch-action: none; overflow: hidden; }

.scrollbarHide::-webkit-scrollbar {
  display: none;
}

.manage-users.send-email-table { margin-top: 1rem; }
.btn { font-weight: 600; }

.send-notification-table.push-notification-table .datatable-header .datatable-header-cell:nth-child(1) .datatable-header-cell-template-wrap, .send-notification-table.push-notification-table .ngx-datatable .datatable-body-cell:nth-child(1) .datatable-body-cell-label, .send-notification-table.push-notification-table .datatable-header .datatable-header-cell:nth-child(5) .datatable-header-cell-template-wrap, .send-notification-table.push-notification-table .ngx-datatable .datatable-body-cell:nth-child(5) .datatable-body-cell-label { text-align: center; width: 100%; }
.push-notification-table .datatable-header .datatable-header-cell:nth-child(1) .datatable-header-cell-template-wrap > div { justify-content: center; }


/* Table css override
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=*/
// .popup-datable .ngx-datatable { overflow: visible !important; position: relative; z-index: 0; }
.ngx-datatable .datatable-body { min-height: 210px; }
// .ngx-datatable .datatable-body, .ngx-datatable .datatable-header { overflow: visible !important; position: relative; z-index: 0; }

.recent-sends-table.send-email-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(1), .recent-sends-table.send-email-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2), .recent-sends-table.send-email-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(3) { text-align: left; justify-content: start !important; }
.recent-sends-table.send-email-table .ngx-datatable .datatable-body-cell:nth-child(1), .recent-sends-table.send-email-table .ngx-datatable .datatable-body-cell:nth-child(2), .recent-sends-table.send-email-table .ngx-datatable .datatable-body-cell:nth-child(3) { justify-content: start; }
.manage-users.send-email-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4) { text-align: left; justify-content: start !important; }
.manage-users.send-email-table .ngx-datatable .datatable-body-cell:nth-child(4) .datatable-body-cell-label { text-align: left !important; }

.datatable-header-cell-template-wrap input:not(last-child) { width: auto; }

.datatable-search { width: 100%; }
.product-pack-table .selectDropdown, app-datatable .selectDropdown { max-width: 300px; }
@media only screen and (max-width: 767px) {
  .product-pack-table .selectDropdown, app-datatable .selectDropdown { max-width: 100%; }
}
.manage-users.product-plan-table .teamApproval .ngx-datatable .datatable-header .datatable-header-cell:nth-child(5) .datatable-header-cell-template-wrap { text-align: left; }
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled .text-disabled { color: #64748b; }

// .popup-datable .visible, .ngx-datatable .datatable-header { min-width: 100%; }
.manage-users.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(8), .manage-users.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(9), .manage-users.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(11) { justify-content: center; }
.manage-users.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(8) .datatable-header-cell-template-wrap, .manage-users.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(9) .datatable-header-cell-template-wrap, .manage-users.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(11) .datatable-header-cell-template-wrap {
  min-width: 120px;
  max-width: 120px;
}

@media only screen and (max-width: 575px) {
  .datatable-footer-inner .footer-container { flex-direction: column; }
  .pagination-controls ul { flex-wrap: wrap; }
}
// Email Events Table
  .email-manage-table-events .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4), 
  .email-manage-table-events .ngx-datatable .datatable-header .datatable-header-cell:nth-child(5) { 
    justify-content: center; 
  }
  .email-manage-table-events .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4) .datatable-header-cell-template-wrap, 
  .email-manage-table-events .ngx-datatable .datatable-header .datatable-header-cell:nth-child(5) .datatable-header-cell-template-wrap {
    min-width: 120px;
    max-width: 120px;
  }
  .email-manage-table-events .ngx-datatable .datatable-body-cell:nth-child(4) .datatable-body-cell-label {
    padding-left: 20px;
  }
.email-manage-table-events .ngx-datatable .datatable-body-cell:nth-child(5) .datatable-body-cell-label { text-align: center !important; }
.package-history-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(8), .package-history-table .popup-datable .datatable-row-center .datatable-body-cell:nth-child(8) .datatable-body-cell-label { text-align: center; justify-content: center; }
.single-event-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(10), .single-event-table .popup-datable .datatable-row-center .datatable-body-cell:nth-child(10) .datatable-body-cell-label { text-align: center; justify-content: center; }
.datatable-body .datatable-body-cell .datatable-body-cell-label span:last-child { margin-right: 0 !important; }
// .manage-users.product-plan-table .popup-datable .datatable-row-center .datatable-body-cell:nth-child(11) .datatable-body-cell-label { text-align: center; justify-content: center; display: flex; }
.manage-users.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(11) { justify-content: start; }
.manage-users.product-plan-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(11) .datatable-header-cell-template-wrap { text-align: left; }
.manage-users.product-plan-table .popup-datable .datatable-row-center .datatable-body-cell:nth-child(11) .datatable-body-cell-label .flex.items-center.ml-2 { margin-left: 0 !important; }
.monthlyDetails.manage-users .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4), .monthlyDetails.manage-users .ngx-datatable .datatable-header .datatable-header-cell:nth-child(7) { justify-content: center; }

.org-custom-ng-select {
  height: 38px !important;
  position: relative;
  bottom: 3px;
}
.manage-users.product-plan-table .teamApproval .ngx-datatable .datatable-header .datatable-header-cell:nth-child(5) .datatable-header-cell-template-wrap { text-align: left; }

.custom_scrollbar_light {
  overflow-y: auto;
}

.custom_scrollbar_light::-webkit-scrollbar {
  width: 5px;
}

.custom_scrollbar_light::-webkit-scrollbar-track {
  background-color: gray;
  border-radius: 10px;
}

.custom_scrollbar_light::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3b82f6;
}

.custom_scrollbar_dark {
  overflow-y: auto;
}

.custom_scrollbar_dark::-webkit-scrollbar {
  width: 5px;
}

.custom_scrollbar_dark::-webkit-scrollbar-track {
  background-color: gray;
  border-radius: 10px;
}

.custom_scrollbar_dark::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #fff;
}

@media only screen and (max-width: 400px) {
  div .w-80 { width: 100%; }
}

.announcement_main_container .youtube-player-placeholder {
  width: 100% !important;
}

.announcement_main_container iframe {
  width: 100% !important;
}

.announcement_main_container video {
  width: 100% !important;
  left: 0 !important;
  top: 0 !important;
}

.view-team-managers-table .datatable-row-center :nth-child(2) > .datatable-body-cell-label, .view-team-managers-table .datatable-row-center :nth-child(3) > .datatable-body-cell-label { text-align: left; }
.view-coach-table .ngx-datatable .datatable-body, .view-team-managers-table .ngx-datatable .datatable-body { min-height: inherit; }

.quill-content {
  @apply text-base leading-normal; /* Prevent Tailwind conflicts */
}

.quill-content p {
  @apply my-2; /* Add spacing to paragraphs */
}

.quill-content strong {
  font-weight: bold !important; /* Ensure bold text works */
}

.quill-content ul {
  @apply list-disc pl-12; /* Properly style unordered lists */
}

.quill-content ol {
  @apply list-decimal pl-6; /* Properly style ordered lists */
}

.quill-content a {
  @apply text-blue-500 underline; /* Ensure links are visible */
}

.quill-content {
  @apply text-base leading-normal; /* Prevent Tailwind from overriding styles */
}

.quill-content span {
  all: unset; /* Ensure inline styles from Quill are respected */
}

.quill-content .ql-editor {
  color: inherit !important; /* Allow Quill's text color */
  background-color: inherit !important; /* Allow Quill's background */
}

.quill-content .ql-bg-black {
  background-color: black !important;
}

.quill-content .ql-color-red {
  color: red !important;
}

.ql-container {
  height: 15rem !important;
  width: 100% !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-color: #e2e8f0;
}

.ql-toolbar.ql-snow {
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border-color: #e2e8f0;
}

.quill_custom_container_class {
  width: 100% !important;
}

.quill_container_div {
  @apply text-base leading-normal; /* Reset Tailwind conflicting styles */
}

.ql-editor {
  @apply font-normal; /* Ensure normal font styles */
}

.ql-editor strong {
  font-weight: bold !important; /* Fix bold issue */
}

.email-preivew-container {
   h1, h2, h3, h4, h5, h6, p, li, span, div {
    color: #000 !important;
    // font-size: 14px;
  }
  .quill-content ul {
    @apply list-disc pl-12; /* Properly style unordered lists */
  }  
}

.ql-toolbar.ql-snow {
  background-color: #fff;
}

#quill_custom_container_class_light {
  color: #000;
  .ql-container.ql-snow {
    border: 1px solid #e2e8f0;
    background: #fff;
  }
  .ql-toolbar.ql-snow {
    border: 1px solid #e2e8f0;
  }
  .ql-editor.ql-blank::before {
    color: #000 !important;  /* Change to your desired color */
    opacity: 1 !important;   /* Ensure visibility */
  }
}

#quill_custom_container_class_dark {
  color: #fff;
  .ql-container.ql-snow {
    border: 1px solid #334155;
    background: #1c2f45;
  }
  .ql-toolbar.ql-snow {
    border: 1px solid #334155;
  }
  .ql-editor.ql-blank::before {
    color: #fff !important;  /* Change to your desired color */
    opacity: 1 !important;   /* Ensure visibility */
  }
}

/* Ensure the Quill editor has a transparent background */
.ql-editor {
  background-color: transparent !important;
}

/* Remove any inline text color but keep the background */
.ql-editor * {
  color: inherit !important; /* Forces text to use the default text color */
}

/* Keep Quill's background formatting */
.ql-editor span[style*="background-color"],
.ql-editor strong[style*="background-color"],
.ql-editor h1[style*="background-color"],
.ql-editor h2[style*="background-color"],
.ql-editor h3[style*="background-color"],
.ql-editor h4[style*="background-color"],
.ql-editor h5[style*="background-color"],
.ql-editor h6[style*="background-color"],
.ql-editor p[style*="background-color"],
.ql-editor code[style*="background-color"] {
  background-color: inherit !important;
}

.ql-snow .ql-editor code {
  background-color: inherit !important;
}

// new design of ngx-datatable *START
.data_table_v2 {
  .datatable-body-row {
    border-bottom: 1px solid #1D334E;
  }
  .datatable-body-cell {
    border-right: 2px solid #1D334E;
    border-left: 2px solid #1D334E;
  }
  .ngx-datatable .datatable-body-cell, .ngx-datatable .datatable-header-cell { 
    min-width: initial !important;
  }
  .datatable-header {
    height: initial !important;
  }
}
// new design of ngx-datatable *END

.product-plan-table-v2 .datatable-row-center .datatable-body-cell:nth-child(6) .datatable-body-cell-label {
  text-align: left !important;
}

.product-plan-table-v2 .datatable-row-center .datatable-body-cell:nth-child(1) {
  border-left: 4px solid #1D334E;
}

.product-plan-table-v2 .datatable-row-center .datatable-body-cell:nth-child(1) {
  border-left: 4px solid #1D334E;
}

.table-nav-list { overflow-x: auto; overflow-y: hidden; }

.data_table_v2 .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(6) {
  justify-content: start !important;
}

.package_payment_plan_data_table .datatable-row-center :nth-child(5) > .datatable-body-cell-label {
  text-align: left;
}

.data_table_v2 .popup-datable.teamApproval .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(6) {
  justify-content: start !important;
}

.eventRegisteredUsersDrawer .selectDropdown, app-datatable .selectDropdown {
  max-width: 100% !important;
}

.role_wise_signup_select.selectDropdown .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #94a3b8 !important;
}

.fc .fc-scrollgrid table, .fc .fc-scrollgrid .fc-daygrid-body {
  min-width: 100%;
}

.fc .fc-button { transition: 0.35s all; }
.fc .fc-button-primary { background: #3b82f6; border-color: #3b82f6; color: #fff; }
.fc .fc-button-primary:hover { background: #2563eb; border-color: #2563eb; color: #fff; }
.fc .fc-button-primary:disabled { background-color: #101824; border-color: #101824; color: #fff; opacity: 1; }
.fc td .fc-daygrid-day-frame { padding: 10px; }
.fc .fc-daygrid-body-balanced .fc-daygrid-day-events { left: 10px; right: 10px; }
.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active { background: #2563eb; border-color: #2563eb; color: #fff; } 
@media only screen and (max-width: 575px) {
  .fc td .fc-daygrid-day-frame { padding: 0; }  
  .fc .fc-daygrid-body-balanced .fc-daygrid-day-events { left: 0; right: 0; position: static; }
  .fc .fc-toolbar.fc-header-toolbar { flex-direction: column; }
}

.app-sketchplay-preview > div { height: 100%; }
.fc .fc-timegrid .fc-scroller-liquid-absolute, .fc .fc-scroller, .fc .fc-daygrid-day-frame { scrollbar-color: transparent transparent; -webkit-overflow-scrolling: touch; }
.fc .fc-list-sticky .fc-list-day > * { z-index: 1; }
.manage-event-shedule .datatable-body-cell-label ul{
  transform: translate(-49%, 0%) !important
}
.fc-v-event .fc-event-main { color: inherit; }
.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link { box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.45); }
.hide-dropdown-icon.ng-select.ng-select-single.custom-ng-select .ng-select-container:after { display: none; }

.fc-event { position: relative; }
// .fc-event:after { content: attr(data-title); visibility: hidden; background-color: #101824; color: white; text-align: center; padding: 5px 12px; border-radius: 5px; position: absolute; left: 50%; bottom: 100%; transform: translateX(-50%); opacity: 0; z-index: 5; white-space: normal; width: auto; min-width: 70%; max-width: 100%; }
.fc-event:hover:after { opacity: 1; visibility: visible; transition: opacity 0.3s ease-in-out; }
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder { padding-left: 0.5rem; }
.popup-datable.teamApproval .datatable-row-center :nth-child(5) > .datatable-body-cell-label { text-align: left; }

.all-users-table-view-registered .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(2) {
  justify-content: center !important;
}

.SMSCommunicationTable.push-notification-table .ngx-datatable .datatable-body-cell, .push-notification-table .ngx-datatable .datatable-header-cell { min-width: 80px !important; }
.package_payment_plan_data_table .popup-datable .datatable-row-center :nth-child(7) > .datatable-body-cell-label, 
.product-plan-table-v2 .popup-datable .datatable-row-center :nth-child(7) > .datatable-body-cell-label { width: 100%; }
.word-break-all { word-break: break-all; }

.fc-event {
  position: relative;
  z-index: 1;
}

.tooltip-content {
  visibility: hidden;
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  background-color: #e2e8f0;
  color: #64748b;
  text-align: left;
  border-radius: 6px;
  padding: 5px 12px;
  position: absolute;
  z-index: 10000; 
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: -10px;
  opacity: 0;
  transition: 0.35s all;
  white-space: normal; 
  word-wrap: break-word; 
  line-height: 1.4;
  max-height: 150px; 
  pointer-events: none;
}

.tooltip-content:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  bottom: -5px;
  height: 0;
  width: 0;
  border-top: 5px solid #e2e8f0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin: auto;
  transition: 0.35s all;
}

.fc-event:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
  margin-bottom: 10px;
  transition-delay: 0.7s;
}
